body {
    font-family: 'Montserrat', sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f2f7fa !important;
}
h1 {
    font-size: 32px;
    margin-bottom: 10px;
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
}
h2 {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
}
P {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: rgba(50, 57, 78, 0.69);
}
.text-center {
    text-align: center;
}
.mb-0 {
    margin-bottom: 0;
}
.MuiList-root .MuiMenuItem-root {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #616a71;
}
.ml-1 {
    margin-left: 0.25rem;
}
img {
    max-width: 100%;
}
.cursor-pointer {
    cursor: pointer;
}
.color-danger {
    color: #e30d0f !important;
}
.color-success {
    color: #0cab7c !important;
}
.color-info {
    color: #2c99ff !important;
}

.csv-link {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    color: #2196f3;
    align-items: center;
    font-size: 10px;
    justify-content: center;
    margin-left: 20px;
    border-radius: 10px;
    padding: 3px 10px;
    height: 42px;
}

.csv-link > span {
    margin-left: 5px;
}

.csv-link:hover {
    background-color: whitesmoke;
}
