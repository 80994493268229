*,  .MuiFormControlLabel-label{
    font-family: Montserrat, sans-serif !important;
}

.searchBox::placeholder, .Mui-checked, .MuiCheckbox-indeterminate {
    color: #00153A !important;
}
.MuiButton-root.contained, .pagination .MuiPagination-root .MuiPagination-ul li button.Mui-selected{
    background-color: #00153A !important;
}

